import { login, getInfo } from '@/api/login'
import { getShareInfo } from '@/api/share'
import { sendWebsocket, closeWebsocket } from '@/utils/websocket/socket'
import { detailRemoveSubscribeCmd } from '@/utils/websocket/data'
import { getToken, setToken, setWs } from '@/utils/auth'

import { setVehicleId, setShareAccountId } from '@/utils/session'
import Cookies from 'js-cookie'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    // 设置最高权限
    permissions: [],
    userInfo: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      let data = {
        username: userInfo.username,
        password: userInfo.password,
        grant_type: 'password'
      }

      return new Promise((resolve, reject) => {
        login(data)
          .then((res) => {
            if (res.code == 200 && res.data) {
              setToken(res.data.access_token)
              setWs(res.data.ws)
              commit('SET_TOKEN', res.data.access_token)
              resolve(res.code)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            if ((res.code = 200 && res.data)) {
              const user = res.data.user

              if (user) {
                const avatar =
                  user.header == ''
                    ? require('@/assets/images/avatar.jpg')
                    : process.env.VUE_APP_FILE_BASE_DOMAIN + user.avatar

                commit('SET_NAME', user.account)
                commit('SET_AVATAR', avatar)
                commit('SET_USER_INFO', user)
              }
              if (res.data.roles && res.data.roles.length > 0) {
                // 验证返回的roles是否是一个非空数组
                commit('SET_ROLES', res.data.roles)
                commit('SET_PERMISSIONS', res.data.permissions)
              } else {
                commit('SET_ROLES', ['ROLE_DEFAULT'])
              }
              resolve(user)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // get share user info
    GetShareUserInfo({ commit }, username) {
      return new Promise(async (resolve, reject) => {
        getShareInfo({ account: username })
          .then((response) => {
            const { data } = response
            if (data) {
              var resData = {
                username: data.account,
                password: data.protection > 0 ? '' : '123456'
              }
              setVehicleId(data.vehicleId)
              setShareAccountId(username)

              resolve(resData)
            } else {
              LogOut()
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 退出系统
    LogOut({ commit, state }) {
      sendWebsocket(detailRemoveSubscribeCmd)
      closeWebsocket()
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_PERMISSIONS', [])
      sessionStorage.clear()
    }
  }
}

export default user
