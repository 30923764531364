const VehicleId = 'vehicle-id'
const ReportId = 'report-id'
const RoleId = 'role-id'
const RoleKey = 'role-key'
const ShareAccountId = 'share-account-id'
export function getVehicleId() {
  return sessionStorage.getItem(VehicleId)
}

export function setVehicleId(id) {
  return sessionStorage.setItem(VehicleId, id)
}

export function removeVehicleId() {
  return sessionStorage.removeItem(VehicleId)
}

export function getReportId() {
  return sessionStorage.getItem(ReportId)
}

export function setReportId(id) {
  return sessionStorage.setItem(ReportId, id)
}

export function removeReportId() {
  return sessionStorage.removeItem(ReportId)
}

export function getRoleId() {
  return sessionStorage.getItem(RoleId)
}

export function setRoleId(id) {
  return sessionStorage.setItem(RoleId, id)
}

export function removeRoleId() {
  return sessionStorage.removeItem(RoleId)
}

export function getRoleKey() {
  return sessionStorage.getItem(RoleKey)
}

export function setroleKey(key) {
  return sessionStorage.setItem(RoleKey, key)
}

export function removeRoleKey() {
  return sessionStorage.removeItem(RoleKey)
}

export function getShareAccountId() {
  return sessionStorage.getItem(ShareAccountId)
}

export function setShareAccountId(id) {
  return sessionStorage.setItem(ShareAccountId, id)
}

export function removeShareAccountId() {
  return sessionStorage.removeItem(ShareAccountId)
}
