export default {
  login: {
    login_remember: 'Remember me',
    forgot_password: 'Forgot password?',
    password_login: 'Password Login',
    login: 'Login',
    login_dec: 'More information about business cooperation.'
  },
  version: {
    version: 'Version V1.3.3',
    version_num: 'V1.3.3'
  },
  placeholder: {
    login_username: 'Please input your email address',
    login_password: 'Please input your password',
    share_password: 'Please input your share password',
    verification_code: 'Verification code',
    new_password: 'Please input a password',
    again_password: 'Please enter the password again',
    search_placeholder: 'Please Enter',
    search_list: 'Search',
    select_placeholder: 'Please Select',
    please_enter: 'Please Enter',
    prealarm_name: 'PreAlarm name',
    judgment_logic: 'Judgment logic',
    prealarm_value: 'PreAlarm value',
    setting_password: 'Change Password'
  },
  rules: {
    value_integer: 'Please enter an integer!',
    email_empty: 'Please enter your email address!',
    email_invalid: 'Please enter a valid email address!',
    warning_empty_passwd: 'Please enter password!',
    warning_low_passwd: 'Password verification failed!',
    warning_low_passwd: 'Password cannot be less than 6 digits!',
    warning_max_passwd:
      'Input cannot be empty and must not exceed 12 characters!',

    error_password_message:
      'Passwords can consist of letters, numbers and special characters!',
    validate_code_empty: 'Please enter the verification code!',
    new_password_empty: 'Please enter the password again!',
    password_no_same: 'Inconsistent passwords!',
    no_empty: 'Please Enter!',
    vehicle_name_max_20:
      'Input cannot be empty and must not exceed 20 characters.',
    vehicle_name_max_30:
      'Input cannot be empty and must not exceed 30 characters.',
    validate_user_name:
      'Input is at least 1 character and cannot exceed 15 characters!',
    role: {
      role_name_empty: 'Please enter Role name!',
      role_key_empty: 'Please select Role Key! ',
      role_menu_empty: 'Please select Menu authority!',
      role_manager_empty: 'Please select Manager authority!',
      role_name_max: 'Input cannot be empty and must not exceed 24 characters!'
    },
    depot: {
      depot_name_empty: 'Please enter Depot name!',
      depot_status_empty: 'Please select Depot status!',
      depot_maneger_empty: 'Please select Depot maneger!'
    },
    fleet: {
      fleet_maneger_empty: 'Please select Fleet maneger!'
    },
    subfleet: {
      subfleet_maneger_empty: 'Please select Sub-fleet maneger!'
    },
    prealarm: {
      prealarm_name_empty: 'Please select Prealarm name!',
      judgment_logic_empty: 'Please select Judgment logic!',
      prealarm_value_empty: 'Please enter Prealarm value!'
    }
  },
  button: {
    confirm: 'Confirm',
    cancel: 'Cancel',
    get_code: 'Get Code',
    unbind: 'Unbind',
    ok: 'OK',
    add: 'Add',
    assign: 'Assign',
    logout: 'Log Out',
    save: 'Save',
    copy: 'Copy',
    return: 'Return',
    login_again: 'Login Again'
  },
  dialog: {
    na: 'N/A',
    edit: 'Edit',
    edit_unit: 'Edit Unit',
    attention: 'Attention',
    register: {
      title: 'TBB FleetPro Register',
      dec: 'Please set your user name and login password.'
    },
    avatar: {
      edit_avatar_title: 'Edit Avatar',
      avatar_preview: 'Avatar Preview',
      upload_avatar: 'Click to upload avatar',
      support: 'Support',
      file_type: 'jpg、png',
      image_format: 'Image Format',
      reupload: 'Reupload'
    },
    detail: {
      share_title: 'Share',
      export_event: 'Export Event',
      guest_users_title: 'Guest Users',
      guest_users_dec:
        'The link has boon closed by tho sharor and tho linik is no longer valid.',
      factory_reset: 'Factory Reset',
      factory_reset_dec: 'Confirm to restore all settings to default values?'
    },
    map: {
      title: 'Map Function'
    },
    forgot: {
      title: 'Forgot password',
      dec: 'Please Change The Password After Obtaining The Email Verification Code '
    },
    change: {
      title: 'Change Password'
    },
    out: {
      title: 'Log Out',
      out_dec: 'Confirm to log out？'
    },
    menu: {
      title: 'Add Menu',
      edit_title: 'Edit Menu'
    },
    account: {
      title: 'Add Account',
      title_manager: 'Manager Turnover',
      disable_dec: 'Are you sure to disable',
      unblock_dec: 'Are you sure to unblock'
    },
    role: {
      title: 'Add Role',
      edit_title: 'Edit Role'
    },
    depot: {
      title: 'Add Depot',
      edit_title: 'Edit'
    },
    unbind: {
      cancel_role_authorization: 'Cancel Authorization',
      unbind_role_dec: 'Are you sure you want to cancel the authorization?'
    },
    delete: {
      delete_depot: 'Delete Depot',
      delete_dec: 'Are you sure to delete',
      delete_vehicle_dec: 'Are you sure you want to delete',
      delete_fleet: 'Delete Sleet',
      delete_subfleet: 'Delete Sub-fleet',
      delete_vehicle: 'Delete Vehicle',
      delete_role: 'Delete Role'
    },
    remove: {
      remove_vehicle: 'Remove Vehicle',
      remove_vehicle_dec: 'Are you sure you want to remove'
    },
    fleet: {
      title: 'Add Fleet',
      edit_title: 'Edit Fleet'
    },
    subfleet: {
      edit_title: 'Edit Sub-fleet',
      title: 'Add Sub-fleet'
    },
    vehicle: {
      group_title: 'Create S/N',
      title: 'Add Vehicle',
      assign_title: 'Assign',
      edit: 'Edit Name'
    },
    report: {
      setting_report: 'Setting Report',
      add_report: 'Add Report',
      edit_report: 'Edit Report'
    },
    prealarm: {
      title: 'Add PreAlarm',
      title_edit: 'Edit PreAlarm',
      soc_dec_tip:
        'The early warning setting is Low SOC Alarm Value, and the recommended setting range is 0~50%.'
    },
    share: {
      title: 'Share'
    },
    version: {
      firmware_attention_title: 'Attention',
      firmware_update_title: 'Firmware Update',
      firmware_update_dec:
        'Do not turn off the power or operate the device during the update process, as this may cause the device to operate abnormally.',
      firmware_updating: 'Updating',
      firmware_updating_tip: 'Firmware updating, do not power off!',
      firmware_updating_success_title: 'Upgrade Successful!',
      firmware_updating_success_dec:
        'Device rebooting in progress , this may take a few minutes.',
      firmware_updating_fail_title: 'Upgrade Failure!',
      firmware_updating_fail_dec: 'Update failed, please try again!',
      firmware_updating_display_tip:
        'Because the Display firmware is relatively large, Display upgrade please go to the APP side, APP side will use Bluetooth communication, during which the phone needs to be close to the Display.'
    }
  },
  form: {
    drag_upload: 'Drag files here or click upload',
    only_files: 'Only allows import of xls/xlsx format files.',
    download_template: 'Download Template',
    one_click: 'One-Click',
    setting: {
      email: 'Email',
      user_name: 'User Name',
      account_email: 'Account(Email)',
      phone_number: 'Phone Number',
      password: 'Password',
      speed_unit: 'Speed unit',
      speed_unit_normal: 'km/h',
      speed_unit_mph: 'mph',
      mileage_unit: 'Mileage unit',
      mileage_unit_normal: 'km',
      mileage_unit_miles: 'miles'
    },
    detail: {
      active_sharing: 'Active Sharing',
      password_protection: 'Password Protection',
      share_tip:
        'If the share is closed and shared again, a new share link is created and the original share link is invalidated.',
      address: 'Address',
      password_setting: 'Password Setting',
      time: 'Time',
      event_time: 'Data（Optional range: Up to 31 days）',
      event_name: 'Event name'
    },
    change: {
      old_password: 'Old password',
      new_password: 'New password',
      again_password: 'Enter the password again'
    },
    account: {
      email: 'Account(Email)',
      status: 'Status',
      role: 'Role',
      user_name: 'User name'
    },
    role: {
      name: 'Role name',
      role: 'Role',
      role_key: 'Role Key',

      menu_authority: 'Menu authority',
      fleet_manager: 'Fleet manager',
      sub_fleet_manager: 'Sub-fleet manager'
    },
    forgot: {
      email: 'Email',
      code: 'Verification code',
      new_password: 'New pssword',
      again_password: 'Enter the password again'
    },
    depot: {
      name: 'Depot name',
      status: 'Status',
      manager: 'Depot manager'
    },
    fleet: {
      name: 'Fleet name',
      status: 'Status',
      manager: 'Fleet manager'
    },
    subfleet: {
      name: 'Sub-fleet name',
      status: 'Status',
      manager: 'Sub-fleet manager'
    },
    vehicle: {
      name: 'Vehicle name',
      sn: 'S/N',
      batch_add: 'Batch add',
      batch_create: 'Batch create',
      vehicle_new_name: 'New name ',
      vehicle_current: 'Current'
    },
    report: {
      set_sub_title: 'Data name',
      report_name: 'Report name',
      description: 'Description'
    },
    prealarm: {
      prealarm_name: 'Prealarm name',
      prealarm_value: 'PreAlarm value',
      judgment_logic: 'Judgment logic'
    },
    share: {
      password: 'Password'
    }
  },
  errorCode: {
    500: 'System exception, please contact the administrator',
    timeout: 'System interface request timeout'
  },
  home: {
    fleetinfo: {
      fleet_option_all_lable: 'ALL',
      fleet_mileage_title: 'Total Mileage ',
      fleet_charge_title: 'Total Charge (kWh)',
      fleet_discharge_title: 'Total Discharge (kWh)'
    }
  },
  detail: {
    tab_real_time: 'Real-Time Data',
    tab_statistical: 'Statistical Data',
    tab_device_info: 'Device Info',
    tab_event: 'Event',
    tab_alarm: 'Alarm',
    real_time_today_charge: 'Today Charge (kWh)',
    real_time_solar_charge: 'Solar Charge (kWh)',
    real_time_today_mileage: 'Today Mileage ',
    real_time_today_dis: 'Today Discharge (kWh)',
    real_time_alt_charge: 'ALT Charge (kWh)',
    real_time_12v_dc_output: '12V DC Output (kWh)',
    real_time_ac_charge: 'AC Charge (kWh)',
    real_time_current_location: 'Current Location',
    real_time_ac_in: 'AC In',
    real_time_ac_load: 'AC load',
    real_time_solar: 'Solar',
    real_time_alt: 'ALT',
    real_time_dc_output: '12V Output',
    real_time_battery_title: 'Battery',
    real_time_inverter_title: 'Inverter',
    real_time_alt_charge_title: 'ALT Charge',
    real_time_solar_charger_title: 'Solar Charger',
    real_time_ac_charger_title: 'AC Charger',
    real_time_dc_output_title: '12V DC Output',
    real_time_in_4g_title: 'In-vehicle 4G signal',

    load: 'Load',
    status: 'Status',
    charging_current: 'Charging Current',
    inv_output_voltage: 'Output Voltage',
    inv_output_current: 'Output Current',
    inv_output_power: 'Output Power',
    inv_input_voltage: 'Input Voltage',
    inv_input_current: 'Input Current',
    inv_output_frequency: 'Output Frequency',
    inv_power: 'Power',
    inv_apparent_power: 'Apparent Power',
    inv_dry_contact: 'Dry Contact',

    battery_dry_contact: 'Dry Contact',
    battery_heating_status: 'Heating Status',
    battery_max_cell_voltage: 'Max Cell Voltage',
    battery_min_cell_voltage: 'Min Cell Voltage',
    battery_type: 'Type',
    battery_capacity: 'Capacity',
    battery_soc: 'SOC',
    battery_time_full: 'Time To Full',
    battery_time_go: 'Time To Go',
    battery_max_temperature: 'Max Cell Temperature',
    battery_min_temperature: 'Min Cell Temperature',
    battery_voltage: 'Voltage',
    battery_current: 'Current',

    solar_pv_voltage: 'PV Voltage',
    solar_output_power: 'Output Power',
    solar_charging_power: 'Charging Power',
    solar_output_current: 'Output Current',

    dc_status: 'Status',
    dc_12v_voltage: '12V Voltage',
    dc_output_current: 'Output Current',
    dc_output_power: 'Output Power',

    alt_voltage: 'ALT Voltage',
    alt_output_current: 'Output Current',
    alt_output_power: 'Output Power',
    alt_alternator_voltage: 'Alternator Voltage',
    alt_charging_power: 'Charging Power',

    tank_title: 'TANK',
    tank_fresh_title: 'Fresh',
    tank_gray_title: 'Gray',
    tank_black_title: 'Black',

    ac_in_input_voltage: 'Input Voltage',
    ac_in_input_current: 'Input Current',
    ac_in_input_frequency: 'Input Frequency',
    ac_in_input_power: 'Input Power',
    ac_in_charge_power: 'Charge Power',
    air_conditioner: 'Air Conditioner',
    gas_title: 'GAS',
    gas_gas1_title: 'Gas1',
    gas_gas2_title: 'Gas2',
    level_title: 'LEVEL',
    tpms_title: 'TPMS',
    tpms_psi_title: 'Psi',
    tpms_front_left_title: 'Front Left',
    tpms_front_right_title: 'Front Right',
    tpms_rear_left_title: 'Rear Left',
    tpms_rear_right_title: 'Rear Right',
    device_info_model_version: 'Model Version',
    device_info_serial_number: 'Serial Number',
    device_info_hardware_version: 'Hardware Version',
    device_info_firmware_code: 'Firmware Code',
    device_info_firmware_version: 'Firmware Version',
    device_info_config_file_version: 'Configuration File Versions',
    device_info_new_version: 'New Version',
    map_current_speed: 'Current Speed',
    map_total_mileage: 'Total Mileage'
  },
  table: {
    system: 'System',
    sn: 'S/N',
    vehicle_name: 'Vehicle name',
    battery_soc: 'Battery SOC',
    owner: 'Owner',
    last_updated: 'Last updated',
    table_suggestion: 'Suggestion',
    fleet_attributed: 'Fleet attributed',
    first_installed_date: 'First installed date',
    last_update: 'Last update',
    table_no_data: 'No data',
    table_app_user: 'APP users',

    table_user_name: 'User name',
    table_account_email: 'Account (Email)',
    table_user_role: 'Role',

    table_status: 'Status',
    table_status_normal: 'Normal',
    table_status_alarm: 'Alarm',
    table_status_offline: 'Offline',
    table_status_disable: 'Disable',

    table_operator: 'Operator',
    table_operation_time: 'Operation time',
    table_operation: 'Operation',

    table_creation_time: 'Creation time',

    table_last_update: 'Last Update',

    table_depot_name: 'Depot name',
    table_depot_manager: 'Depot manager',

    table_fleet_name: 'Fleet name',
    table_fleet_manager: 'Fleet manager',

    table_subfleet_name: 'Sub-fleet name',
    table_subfleet_manager: 'Sub-fleet manager',
    table_subfleet_total_vehicle: 'Total vehicle',
    table_subfleet_alarm_vehicle: 'Alarm vehicle',
    table_subfleet_upgrade_required: 'Upgrade required',

    table_report_name: 'Report name',
    table_report_description: 'Description',
    table_report_daily_residual_soc: 'Daily residual soc',
    table_report_solar: 'Solar',
    table_report_slt: 'ALT',
    table_report_inv: 'INV',
    table_report_mileage: 'Mileage',
    table_report_data_name: 'Data name',

    table_event_device_name: 'Device name',
    table_event_Description: 'Description',
    table_event_start_time: 'Start time',
    table_event_finish_time: 'Finish time'
  },
  echarts: {
    month: 'Month',
    day: 'Day',
    charge: 'Charge',
    discharge: 'Discharge'
  },
  unit: {
    kwh: 'kWh'
  },
  datePicker: {
    start_date: 'Start Date',
    end_date: 'End Date',
    last_7_day: 'Last 7 days',
    last_30_day: 'Last 30 days',
    this_week: 'This Week',
    this_month: 'This Month',
    this_year: 'This Year'
  },
  list: {
    home: {
      title: 'Alarm List'
    },
    common: {
      pageTotal: 'pages in total',
      currentPage: 'current page',
      vehicle: 'Vehicle'
    }
  },
  menu: {
    home: 'Home',
    detail: 'Vehicle Details Page',
    alarm: 'Alarm Detail',
    default_report: 'Default Report',
    current_role: 'Current Role',
    setting: 'Settings',
    airconditioner: 'Air Conditioner Details Page'
  },
  common: {
    no_data: 'No Data',
    total: 'Total',
    normal: 'Normal',
    offline: 'Offline',
    alarm: 'Alarm',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    refreshPos: 'Refresh Positioning',
    alarm: 'Alarm',
    detail: 'Detail',
    change: 'Change',
    delete: 'Delete',
    edit: 'Edit',
    assign_oles: 'Assign Roles',
    assign_vehicles: 'Assign vehicles',
    unbind: 'Unbind',
    export: 'Export',
    set: 'Set',
    save: 'Save',
    reset: 'Reset',
    add: 'Add',
    screen: 'Screen',
    refresh: 'Refresh',
    location: 'Location',
    share: 'Share',
    setting: 'Setting',
    return: 'Return',
    remove: 'Remove',
    transfer: 'Transfer'
  },
  loadding: {
    text: 'Loading...'
  },
  state: {
    state_standby: 'Standby',
    state_charging: 'Charging',
    state_discharge: 'Discharge',
    state_fault: 'Fault',
    state_initialization: 'Initialization',
    state_await: 'Await',
    state_pre_charging: 'Pre Charging',
    state_heating: 'Heating',
    state_bypass: 'Bypass',
    start_electric_supply: 'Electric Supply',
    state_inv: 'Inv',
    state_assist: 'Assist',
    state_normal: 'Normal',
    state_alarm: 'Alarm',
    state_offline: 'Offline'
  },
  footer: {
    copy_ltd: 'Copyright 2007-2024TBB POWER(XIAMEN)CO.,LTD.',
    icp: '(闽ICP备17020261号-17)',
    ltd: 'TBB POWER Co.,Ltd'
  },
  message: {
    transfer_message_info: 'Successfully transferred!',
    add_message_info: 'Successfully added!',
    edit_message_info: 'Successfully modified!',
    save_message_info: 'Successfully save!',
    del_message_info: 'Successfully deleted!',
    unbind_message_info: 'Successfully unbind!',
    assigned_message_info: 'Successfully assigned!',
    message_info_copy_link_success: 'Copy link succeeded',
    message_info_copy_link_fail: 'Copy link failed',
    message_select_assigned_vehicle: 'Please select the assigned vehicle',
    message_register_success: 'Successfully registered account!',
    message_send_code_success: 'Successfully sent verification code!',
    message_send_code_fail: 'Please enter your email address!',
    message_upload: 'Please upload the file!',
    message_picker_select_limit: 'Selected date range up to',
    message_days: ' days',
    forget_success_message_info: 'Password reset successful!',
    change_success_message_info: 'Password change successfu!'
  },
  error: {
    error_password: 'Password error !',
    prompted: 'Prompted',
    error: 'Error',
    erro_page: 'Unable to find webpage!',
    error_404_info:
      'Sorry, the page you are looking for does not exist. Try checking for errors in the URL, then press the refresh button on the browser or try to find other content in our application.',
    error_tag: 'Please set the value of the operation permission label',
    error_login_token: 'Login status has expired, please log in again!',
    error_status_changed: 'The user status has changed!',
    error_role_changed: 'The user role has changed!',

    error_ws_token: 'Websocket status has expired, please log in again!',

    error_system_prompt: 'System Prompt',
    erroe_401_info:
      'Invalid session, or session has expired, please log in again.'
  }
}
