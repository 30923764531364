import { getWs } from '@/utils/auth'

import { EventBus } from '@/utils/eventBus'

import DialogTip from '@/components/PlugDialog/instance'

let global_callback = null
let socket = null

// 创建
export const initWebSocket = function () {
  if (getWs() && !socket) {
    socket = new WebSocket(getWs())
    socketOnClose()

    socketOnOpen()

    socketOnMessage()
    socketOnError()
  }
}

// 关闭
export const closeWebsocket = function () {
  if (socket) {
    socket.close()
  }
}

// 发送
export const sendWebsocket = function (agentData, callback) {
  global_callback = callback
  let data = JSON.stringify(agentData)

  if (socket) {
    if (socket.readyState == 1) {
      // 已经打开,可以直接发送
      socket.send(data)
    }
  } else {
    // 未打开，则开启后重新调用
    if (getWs()) {
      initWebSocket()
      sendWebsocket(agentData, callback)
    }
  }
}

function socketOnClose() {
  socket.onclose = () => {
    let successNum = Number(sessionStorage.getItem('socket-init-num'))

    sessionStorage.setItem('socket-init-num', Number(successNum) + 1)

    if (successNum < 4) {
      socket = null
      initWebSocket()
    } else {
      DialogTip()
    }

    console.log('socket已经关闭')
  }
}

function socketOnOpen() {
  socket.onopen = () => {
    console.log('socket连接成功')
    EventBus.$emit('socketOpen')
  }
}

function socketOnMessage() {
  socket.onmessage = (e) => {
    let mesData = JSON.parse(e.data)

    if (global_callback) {
      if (mesData.cmd == 0 || mesData.cmd == 1 || mesData.cmd == 8) {
        return
      } else if (mesData.cmd == 161) {
        DialogTip()
        sessionStorage.setItem('socket-cmd', mesData.data.messageId)
      } else if (mesData.cmd == 19) {
        EventBus.$emit('socketVehicleStatus', mesData)
      } else if (mesData.cmd == 149) {
        EventBus.$emit('socketFirmwareBurn', mesData)
      } else if (mesData.cmd == 97) {
        EventBus.$emit('socketVehicleVersion', mesData)
      } else if (mesData.cmd == 144) {
        EventBus.$emit('socketVehicleChangeKeyList', mesData)
      } else {
        return global_callback(mesData)
      }
    } else {
      if (mesData.cmd == 161) {
        DialogTip()
        sessionStorage.setItem('socket-cmd', mesData.data.messageId)
      } else if (mesData.cmd == 19) {
        EventBus.$emit('socketVehicleStatus', mesData)
      } else if (mesData.cmd == 149) {
        EventBus.$emit('socketFirmwareBurn', mesData)
      } else if (mesData.cmd == 97) {
        EventBus.$emit('socketVehicleVersion', mesData)
      } else if (mesData.cmd == 144) {
        EventBus.$emit('socketVehicleChangeKeyList', mesData)
      }
    }
  }
}

function socketOnError() {
  socket.onerror = () => {
    // console.log('socket连接出错')
    // initWebSocket()
  }
}
